// import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import Footer from "../components/Footer/Footer";
// import NavBar from "../components/NavBar/NavBar";
// import HeroSection from "../components/Hero.jsx";
// import MissionSection from "../components/Mission";
// import WhyChooseSection from "../components/WhyChoose";
// import Features from "../components/Features";
// import Promises from "../components/Promises";
// import Courses from "../components/Courses";
// import FAQ from "../components/FAQ";

// const Home = () => {
//   const navigate = useNavigate();

//   return (
//     <div>
//       <NavBar />
//       <HeroSection />
//       <Courses />
//       <MissionSection />
//       <WhyChooseSection />
//       <Features />
//       <Promises />
//       <FAQ />
//       <Footer />
//     </div>
//   );
// };

// export default Home;


import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import NavBar from "../components/NavBar/NavBar";
import HeroSection from "../components/Hero.jsx";
import MissionSection from "../components/Mission";
import WhyChooseSection from "../components/WhyChoose";
import Features from "../components/Features";
import Promises from "../components/Promises";
import Courses from "../components/Courses";
import FAQ from "../components/FAQ";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "The Interns - Home";
  }, []);

  return (
    <div>
      <Helmet>
        <title>The Interns - Home</title>
        <meta name="description" content="Welcome to The Interns, your one-stop solution for hiring talented interns." />
        <meta name="keywords" content="interns, hire interns, internships, talented interns, student interns" />
        <meta property="og:title" content="The Interns - Home" />
        <meta property="og:description" content="Welcome to The Interns, your one-stop solution for hiring talented interns." />
        <meta property="og:image" content="%PUBLIC_URL%/TheInternslogo.png" />
        <meta property="og:url" content="http://yourwebsiteurl.com/home" />
        <meta property="og:type" content="website" />
      </Helmet>
      <NavBar />
      <main>
        <HeroSection />
        <Courses />
        <MissionSection />
        <WhyChooseSection />
        <Features />
        <Promises />
        <FAQ />
      </main>
      <Footer />
    </div>
  );
};

export default Home;

